$colors: (
    'primary': #616af3,
    'primary-light': #ded3ff,
    'primary-dark': #2a00a2,
    'secondary': #1cc8ee,
    'secondary-light': #d5f7ff,
    'secondary-dark': #0096b7,
    'accent': #6425ec,
    'light': #f2f3ff,
    'info': #4b597b,
    'warning': #ffc24d,
    'success': #25d621,
    'success-light': #b8ffb7,
    'success-dark': #8bc34a,
    'danger': #ef626c,
    'danger-dark': #ff5722,
    'dark': #3e376f,
    'text': #4b597b,
    'title': #848484,
    'white': #ffffff,
    'black': #000,
    'offwhite': #fcfcfc,
    'offwhite-bg': #f7f7fc,
    'muted': #c4c4c4,
    'grey': #acacac,
    'shadow': rgba(97, 106, 243, 0.25),
    'gold': #ffcd48,
    'transparent': rgba(0, 0, 0, 0),
    'before': #fbb68e,
    'after': #b1d0ff
);

@function theme-color($key: 'primary', $variant: 'base') {
    $map: map-get($colors, $key);
    @return map-get($map, $variant);
}

// Editor card animation time
$card-anim-time: 0.3s;

/**
 * Responsive code
 * TODO: Use Bootstrap's variables directly
 */

// A map of breakpoints.
$breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

// Respond above.
@mixin respond-above($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (min-width: $breakpoint-value) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin respond-below($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin underline-mixin {
    // Extrude highlight a bit
    padding: 2px 0;
    margin: -2px 0;
    border-radius: 3px;

    transition: border-bottom-color $card-anim-time ease-in-out;
    border-bottom: 2px solid transparent;
}

@mixin editor-font-mixin {
    // Editor text styles
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    font-family: 'Inter', sans-serif;
    line-height: 1.8;
    letter-spacing: -0.003em;
    color: rgba(0, 0, 0, 1);

    h1,
    h2,
    h3,
    h4 {
        padding-top: 0.2em;
        margin-bottom: 0;
    }
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 1.2rem;
    }
    h4 {
        font-size: 1.1rem;
    }

    ol,
    ul {
        list-style-position: inside;
        padding-left: 20px;
    }
}

.text-small {
    font-size: 0.8rem;
}

$navbar-height: 44px;
$toolbar-height: 36px;
