@use '../../style/util';

.row {
    height: 100%;
}

.wrapper {
    padding: 0;

    @include util.respond-below(md) {
        min-height: -webkit-fill-available;
        height: 100%;
    }

    @include util.respond-above(md) {
        min-height: 100vh;
    }
}

.card {
    @include util.respond-below(md) {
        height: 100%;
        overflow-y: auto;
        padding: 0 0 80px 0;
    }

    @include util.respond-above(sm) {
        border-radius: 15px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
    }
}
