.container {
    overflow-y: scroll;
}

.spinnerContainer {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
}
